import React from 'react';
import './Photography.css';
import Navbar from '../Navbar/Navbar';
import Kennencoverphoto from '../images/coverphotos/Kennen/Kennen.jpg';


function Covers() {
  const images = [
    {
      src: Kennencoverphoto,
      link: './photography/kennen', 
      alt: 'Kennen Dickens',
    },
    {
      src: 'path/to/your/image2.jpg',
      link: '/gallery/image2',
      alt: 'Description of image 2',
    },
    {
      src: 'path/to/your/image3.jpg',
      link: '/gallery/image3',
      alt: 'Description of image 3',
    },
  ];

  return (
    <div className="Covers">
      <Navbar />
      <h1>Photography Gallery</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <a key={index} href={image.link}>
            <img src={image.src} alt={image.alt} className="thumbnail" />
          </a>
        ))}
      </div>
    </div>
  );
}

export default Covers;
