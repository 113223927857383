import React from 'react';
import './Kennen.css';
import Navbar from '../../../Navbar/Navbar';

import Kennen1 from '../../../images/galleryphotos/Kennen/Kennen1.jpg';
import Kennen2 from '../../../images/galleryphotos/Kennen/Kennen2.jpg';
import Kennen3 from '../../../images/galleryphotos/Kennen/Kennen3.jpg';
import Kennen4 from '../../../images/galleryphotos/Kennen/Kennen4.jpg';
import Kennen5 from '../../../images/galleryphotos/Kennen/Kennen5.jpg';
import Kennen6 from '../../../images/galleryphotos/Kennen/Kennen6.jpg';

function Kennen() {
  const images = [
    {
      src: Kennen1,
      alt: 'Kennen Dickens',
    },
    {
      src: Kennen2,
      alt: 'Kennen Dickens',
    },
    {
      src: Kennen3,
      alt: 'Kennen Dickens',
    },
    {
      src: Kennen4,
      alt: 'Kennen Dickens',
    },
    {
      src: Kennen5,
      alt: 'Kennen Dickens',
    },
    {
      src: Kennen6,
      alt: 'Kennen Dickens',
    },
  ];

  return (
    <div className="Kennen">
      <Navbar />
      <h1>Kennen Dickens Photography Gallery</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <a key={index} href={image.link}>
            <img src={image.src} alt={image.alt} className="thumbnail" />
          </a>
        ))}
      </div>
    </div>
  );
}

export default Kennen;