import React from 'react';
import './Resume.css';
import Navbar from '../Navbar/Navbar';
import resume from '../images/Resume.png';


function Resume() {
  return (
    <div className="Resume">
      <Navbar />
      <header className="Resume-header">
        <div className="resume-content">
          <div className="resume-header">
            <h1>Clay O’Leary</h1>
            <p>Dallas, TX, USA</p>
            <p>clayoleary@gmail.com • 432-312-6080 • 
              <a href="https://www.linkedin.com/in/clay-oleary"> linkedin.com/in/clay-oleary</a> • 
              <a href="https://github.com/Captainlearyo"> Captainlearyo@github.com</a> •
              Instagram: <a href="https://www.instagram.com/captainlearyo/"> @captainlearyo</a>
            </p>
          </div>
          
          <div className="resume-section">
          <img src={resume} alt="Resume" className="resume-image" />
          </div>
           {/* Button to download PDF */}
          <div className="resume-download">
            <a href="/images/Clay O'Leary Resume 8_27_24.pdf" download className="download-button">Download Resume</a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Resume;

