import React from 'react';
import logo from '../images/logo.png';
import './Main.css';
import Navbar from '../Navbar/Navbar';

function Main() {
  return (
    <div className="Main">
      <Navbar />
      <header className="Main-header">
        <p>
          Welcome to Clay's Place
        </p>
        <img src={logo} className="Main-logo" alt="logo" />
      </header>
    </div>
  );
}

export default Main;