import React from 'react';
import './Contact.css';
import Navbar from '../Navbar/Navbar';

function Contact() {
  return (
    <div className="Contact">
      <Navbar />
      <div className="Contact-header">
        <h2>Contact Information</h2>
        <p><strong>Name:</strong> John Clay O'Leary</p>
        <p><strong>Email:</strong> Clayoleary@gmail.com</p>
        <p><strong>Phone:</strong> +14323126080</p>
        <p><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/clay-oleary">www.linkedin.com/in/clay-oleary</a></p>
        <p><strong>Instagram:</strong> <a href="https://www.instagram.com/captainlearyo/">@captainlearyo</a></p>
        {/* Add more contact fields as needed */}
      </div>
    </div>
  );
}

export default Contact;
